/*!

=========================================================
* Material Dashboard PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import ErrorPage from "views/Pages/ErrorPage.jsx";
import LoginPage from "views/Pages/LoginPage.jsx";
import KingPricePage from "views/Pages/KingPricePage";
import TakeMeHomePage from "views/Pages/TakeMeHomePage";
import GetDrivenPage from "views/Pages/GetDrivenPage";
import ClearGroupPage from "views/Pages/ClearGroupPage";

var dashRoutes = [
  {
    path: "/king-price-page",
    name: "King Price",
    mini: "KP",
    component: KingPricePage,
    layout: "/admin",
    roles: [
      "KING PRICE PORTAL",
      "ADMINISTRATOR",
      "MEMBER MANAGEMENT",
      "CALL CENTRE"
    ]
  },
  {
    path: "/take-me-home-page",
    name: "Take Me Home",
    mini: "TP",
    component: TakeMeHomePage,
    layout: "/admin",
    roles: [
      "TAKE ME HOME PORTAL",
      "ADMINISTRATOR",
      "MEMBER MANAGEMENT",
      "CALL CENTRE"
    ]
  },
  {
    path: "/get-driven-page",
    name: "Get Driven",
    mini: "GD",
    component: GetDrivenPage,
    layout: "/admin",
    roles: [
      "GET DRIVEN PORTAL",
      "ADMINISTRATOR",
      "MEMBER MANAGEMENT",
      "CALL CENTRE"
    ]
  },
  {
    path: "/clear-group-page",
    name: "Clear Group",
    mini: "CG",
    component: ClearGroupPage,
    layout: "/admin",
    roles: [
      "CLEAR GROUP PORTAL",
      "ADMINISTRATOR",
      "MEMBER MANAGEMENT",
      "CALL CENTRE"
    ]
  },
  {
    path: "/login-page",
    name: "Login Page",
    rtlName: "هعذاتسجيل الدخول",
    mini: "L",
    rtlMini: "هعذا",
    component: LoginPage,
    layout: "/auth",
    display: "hide"
  },
  {
    path: "/error-page",
    name: "Error Page",
    rtlName: "صفحة الخطأ",
    mini: "E",
    rtlMini: "البريد",
    component: ErrorPage,
    layout: "/auth",
    display: "hide"
  }
];
export default dashRoutes;
