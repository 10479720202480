/* tslint:disable:no-empty */
import * as React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import { createStyles, withStyles } from "@material-ui/core/styles";

// @ts-ignore
import SweetAlert from "react-bootstrap-sweetalert"; // untyped

// styles for buttons on sweetalert
import sweetAlertStyle from "../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

const styles = () =>
  createStyles({
    ...sweetAlertStyle
  });

class AlertError extends React.Component {
  state = {
    open: false,
    title: "Error",
    contentText: null,
    okText: "OK",
    onConfirm: () => {}
  };

  openDialog = () => {
    this.setState({ open: true });
  };

  render() {
    const { title, contentText, okText, open } = this.state;
    const { classes } = this.props;
    return (
      <div>
        {open ? (
          <SweetAlert
            error={true}
            style={{ display: "block", marginTop: "-100px", color: "#3c4858" }}
            title={title}
            // tslint:disable-next-line:jsx-no-lambda
            onConfirm={this.handleConfirmClicked}
            confirmBtnCssClass={`${classes.button} ${classes.success}`}
            confirmBtnText={okText}
          >
            {contentText}
          </SweetAlert>
        ) : null}
      </div>
    );
  }

  handleConfirmClicked = () => {
    this.hideAlert();
    this.state.onConfirm();
  };

  showAlert(params) {
    const { title, contentText, okText, onConfirm } = params;
    this.setState(
      prevState => ({
        title: title || prevState.title,
        contentText: contentText || prevState.contentText,
        okText: okText || prevState.okText,
        onConfirm: onConfirm || prevState.onConfirm
      }),
      () => this.openDialog()
    );
  }

  hideAlert() {
    this.setState({
      open: false
    });
  }
}

AlertError.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(AlertError);
