import React, { useState, useEffect, useRef } from "react";
import useAxios from "@use-hooks/axios";
import https from "https";
import moment from "moment";
// import jwtDecode from "jwt-decode";

import { makeStyles } from "@material-ui/styles";

import { Paper, IconButton, InputBase, Box } from "@material-ui/core";
import MuiTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

// custom components
import AlertError from "../../../components/Alert/AlertError";

// Icons
import SearchIcon from "@material-ui/icons/Search";
import Assignment from "@material-ui/icons/Assignment";

import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import Table from "../../../components/Table/Table.jsx";
import Card from "../../../components/Card/Card.jsx";
import CardHeader from "../../../components/Card/CardHeader.jsx";
import CardIcon from "../../../components/Card/CardIcon.jsx";
import CardBody from "../../../components/Card/CardBody.jsx";

import kingpriceLogo from "assets/img/logo-kingprice.png";

// Styles
import dashboardStyle from "../../../assets/jss/material-dashboard-pro-react/views/dashboardStyle";

const useStyles = makeStyles(() => ({
  ...dashboardStyle,
  container: {
    padding: 10,
    marginTop: 20,
    marginBottom: 20
  },
  searchContainer: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "100%",
    maxWidth: 400
  },
  input: {
    marginLeft: 8,
    flex: 1
  },
  iconButton: {
    padding: 10
  }
}));

const PLAN_IDS = ['73c192b2-7e81-40ec-9622-8b1c5bd67d6d','73c192b2-7e81-40ec-9622-8b1c5bd67d6d']

const KingPricePage = () => {
  const classes = useStyles();

  // const [idNumber, setIdNumber] = useState("8905166327181");
  const [searchValue, setSearchValue] = useState(
    process.env.NODE_ENV === "development" ? "8006105041080" : ""
  ); // 8106170146085 // 8804010298088 // 7501315155085
  const [member, setMember] = useState(undefined);
  const [trips, setTrips] = useState([]);
  const [search, setSearch] = useState(false);

  const alertError = useRef(AlertError);

  const token = localStorage.getItem("token");
  // const decoded = token && jwtDecode(token);
  // const { sub } = decoded || {};

  const { response, loading, error, reFetch } = useAxios({
    // url: `https://www.roadtrip.co.za/utils/getmember.php?value=${searchValue}`,
    url: `${process.env.REACT_APP_API_URL}/members/search/${searchValue}`,
    method: "GET",
    options: {
      httpsAgent: new https.Agent({
        rejectUnauthorized: false
      }),
      headers: {
        Authorization: `Bearer ${token}`
      }
    },
    trigger: search,
    forceDispatchEffect: () => !!search
  });

  const { response: vResponse } = useAxios({
    // url: `https://www.roadtrip.co.za/utils/getvehicles.php?value=${member &&
    //  member.id}`,
    url: `${process.env.REACT_APP_API_URL}/members/ids/${member &&
      member.id}/vehicles`,
    method: "GET",
    options: {
      httpsAgent: new https.Agent({
        rejectUnauthorized: false
      }),
      headers: {
        Authorization: `Bearer ${token}`
      }
    },
    trigger: member,
    forceDispatchEffect: () => !!member
  });

  const cur = member && moment(member.policy_date);
  const next =
    member &&
    (member.policy_date
      ? moment()
          .year(2019)
          .month(cur.month())
          .date(cur.date() - 1)
      : moment());
  const last =
    member && next >= moment()
      ? next.subtract("year", 1).subtract("day", 1)
      : next;

  const { response: tcResponse, loading: tcLoading, error: tcError } = useAxios(
    {
      // url: `${reportURL}/generate`,
      url: `${process.env.REACT_APP_API_URL}/members/ids/${member &&
        member.id}/trips`,
      method: "GET",
      options: {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      },
      trigger: member,
      forceDispatchEffect: () => !!member
    }
  );

  useEffect(() => {
    if (!loading) {
      setTrips([]);
      if (error) {
        setMember(undefined);
        setSearch(false);
        alertError.current.showAlert({
          title: error.toJSON() && error.toJSON().name,
          contentText: error.toJSON() && error.toJSON().message
        });
      } else if (response && search) {
        setSearch(false);
        const members = response.data.result.filter(
          row => PLAN_IDS.includes(row.plan_id) && !Number(row.disabled)
        );
        if (!members.length) {
          setMember(undefined);
          alertError.current.showAlert({
            title: "Error occurred",
            contentText: "ID number not found"
          });
        } else if (members.length > 1) {
          setMember(undefined);
          alertError.current.showAlert({
            title: "Error occurred",
            contentText: "Multiple records found"
          });
        } else {
          setMember(members[0]);
        }
      }
    }
  }, [loading]);

  useEffect(() => {
    if (!tcLoading) {
      if (tcError) {
        setTrips([]);
        alertError.current.showAlert({
          title: tcError.toJSON() && tcError.toJSON().name,
          contentText: tcError.toJSON() && tcError.toJSON().message
        });
      } else if (!tcLoading && tcResponse) {
        setTrips(tcResponse.data.rows || []);
      }
    }
  }, [tcLoading]);

  const onKeyDown = event => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const handleSearch = () => {
    setSearchValue.length > 0 && setSearch(true);
    setTrips([]);
    setMember(undefined);
  };

  const vehicles = (vResponse && vResponse.data.result) || [];

  const remaining =
    tcLoading || (member && member.disabled === "1")
      ? 0
      : Math.max(
          0,
          vehicles.length * 6 -
            trips.filter(trip => moment(trip.date, "DD/MM/YYYY").diff(last) > 0)
              .length
        );

  return (
    <React.Fragment>
      <GridContainer>
        <Card>
          <CardHeader icon>
            <CardIcon
              color="rose"
              style={{ background: "linear-gradient(60deg, #d6001d, #ae0f1e)" }}
            >
              <img src={kingpriceLogo} style={{ maxWidth: 200 }} />
            </CardIcon>
          </CardHeader>
          <CardBody>
            <ul>
              <li>
                Kings Cab Benefit (Chauffeur services only) - Please enter the
                clients ID number or cell phone number to determine how many
                trips the client has left.
              </li>
              <li>
                Each client receives 6 trips every 12 months, from the date they
                signed up.
              </li>
              <li>
                Free trips include chauffeur service up to 50km’s – additional
                km&apos;s R10/km to be collected telephonically or cash to
                driver
              </li>
              <li>
                Chauffeur service only, if client requests a Roadcab then quote
                ad hoc trip rate.
              </li>
              <li>
                Here is the link to the benefit if you require{" "}
                <a href="https://www.kingprice.co.za/about/kings-cab/">
                  https://www.kingprice.co.za/about/kings-cab/
                </a>
              </li>
              <li>
                If you are unable to find a client during Office hours
                Monday-Friday 8am-5pm – please email
                alicia.roberts@kingprice.co.za ;
                Johann.Kasselman@kingprice.co.za ;
                rudolph.sinden@kingprice.co.za or call 012 001 8226 and advise
                the client you will return their call.{" "}
                <b>This must be done before advising them of the R450 charge</b>
              </li>
              <li>
                <b>
                  Outside of Office hours, if you are unable to find the client,
                  please assist them at NO CHARGE and ensure you email
                  matthew@roadtrip.co.za &amp; khutso@roadtrip.co.za with all of
                  the details
                </b>
              </li>
              <li>
                If you can find the client but they have 0 trips remaining,
                their balance will go back to 6 trips on the anniversary of
                their sign up date, then please assist client at our ad hoc
                rates R450 for 1-30km and R10/km thereafter to be collected by
                the driver, then email matthew@roadtrip.co.za and
                khutso@roadtrip.co.za
              </li>
            </ul>
          </CardBody>
        </Card>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12}>
          <Paper className={classes.searchContainer} elevation={1}>
            <InputBase
              className={classes.input}
              placeholder="Enter ID Number..."
              value={searchValue}
              onChange={event => setSearchValue(event.target.value)}
              onKeyDown={onKeyDown}
            />
            <IconButton
              className={classes.iconButton}
              aria-label="Search"
              onClick={handleSearch}
            >
              <SearchIcon />
            </IconButton>
          </Paper>
        </GridItem>
        {member ? (
          <Card
            style={{
              backgroundColor: member.disabled === "1" ? "#ffcccb" : "#ffffff"
            }}
          >
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                {member.first_name} {member.surname}
              </h4>
            </CardHeader>
            <CardBody
              style={{
                backgroundColor: member.disabled === "1" ? "#ffcccb" : "#ffffff"
              }}
            >
              <GridContainer>
                <GridItem GridItem xs={5}>
                  <GridContainer>
                    <GridItem xs={6} style={{ marginBottom: 20 }}>
                      <Box fontWeight="fontWeightBold">Trips Remaining</Box>
                    </GridItem>
                    <GridItem xs={6}>
                      <Box
                        fontSize="32pt"
                        fontWeight={
                          remaining < 1 ? "fontWeightBold" : "fontWeightNormal"
                        }
                        color={remaining < 1 ? "error.main" : "text.primary"}
                      >
                        {remaining}
                      </Box>
                    </GridItem>
                    <GridItem xs={6}>
                      <Box fontWeight="fontWeightBold">ID Number</Box>
                    </GridItem>
                    <GridItem xs={6}>{member.id_number}</GridItem>
                    <GridItem xs={6}>
                      <Box fontWeight="fontWeightBold">Cell Number</Box>
                    </GridItem>
                    <GridItem xs={6}>{member.contact_cellular}</GridItem>
                    <GridItem xs={6}>
                      <Box fontWeight="fontWeightBold">Membership Number</Box>
                    </GridItem>
                    <GridItem xs={6}>{member.member_number}</GridItem>
                    <GridItem xs={6}>
                      <Box fontWeight="fontWeightBold">Policy Number</Box>
                    </GridItem>
                    <GridItem xs={6}>{member.policy_number}</GridItem>
                    <GridItem xs={6}>
                      <Box fontWeight="fontWeightBold">Inception Date</Box>
                    </GridItem>
                    <GridItem xs={6}>
                      {member.policy_date
                        ? moment(member.policy_date).format("YYYY-MM-DD")
                        : null}
                    </GridItem>
                    <GridItem xs={6}>
                      <Box fontWeight="fontWeightBold">Account Disabled</Box>
                    </GridItem>
                    <GridItem xs={6}>
                      <Box
                        fontWeight={
                          member.disabled === "1"
                            ? "fontWeightBold"
                            : "fontWeightNormal"
                        }
                        color={
                          member.disabled === "1"
                            ? "error.main"
                            : "text.primary"
                        }
                      >
                        {member.disabled === "1" ? "YES" : "NO"}
                      </Box>
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem GridItem xs={7}>
                  <MuiTable className={classes.table} size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Registration</TableCell>
                        <TableCell>Make</TableCell>
                        <TableCell>Model</TableCell>
                        <TableCell>Colour</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {vehicles.map(vehicle => (
                        <TableRow key={vehicle.registration}>
                          <TableCell component="th" scope="row">
                            {vehicle.registration}
                          </TableCell>
                          <TableCell>
                            {vehicle.manufacturer_description}
                          </TableCell>
                          <TableCell>{vehicle.model}</TableCell>
                          <TableCell>{vehicle.colour_description}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </MuiTable>
                </GridItem>
                <GridItem xs={12}>
                  <Table
                    tableHeaderColor="primary"
                    tableHead={[
                      "Account num",
                      "Trip date",
                      "Trip start",
                      "Job end",
                      "Passenger",
                      "Pick up",
                      "Drop off",
                      "Driver",
                      "Distance"
                    ]}
                    tableData={trips
                      .sort((firstEl, secondEl) =>
                        Number(firstEl.job_id) > Number(secondEl.job_id)
                          ? -1
                          : 1
                      )
                      .map(row => [
                        row.account_num,
                        row.date,
                        row.start,
                        row.end,
                        row.passenger,
                        row["pick-up"],
                        row.drop_off,
                        row.driver,
                        row.distance
                      ])}
                    coloredColls={[3]}
                    colorsColls={["primary"]}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        ) : null}
        <AlertError innerRef={alertError} />
      </GridContainer>
    </React.Fragment>
  );
};

export default KingPricePage;
