/*!

=========================================================
* Material Dashboard PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState, useRef } from "react";
import useAxios from "@use-hooks/axios";
import https from "https";
import {
  Redirect,
  withRouter,
  RouteComponentProps
} from 'react-router-dom';

// @material-ui/core components
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

import { makeStyles } from "@material-ui/styles";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

// custom components
import AlertError from "../../components/Alert/AlertError";

import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";

const useStyles = makeStyles(theme => ({
  ...loginPageStyle(theme),
  logo: {
    maxWidth: "75%",
    padding: theme.spacing(1)
  }
}));

const LoginPage = () => {
  const classes = useStyles();

  const [cardAnimation, setCardAnimation] = useState("cardHidden");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [authenticate, setAuthenticate] = useState(false);
  const [redirectToReferrer, setRedirectToReferrer] = useState(false);

  const alertError = useRef(AlertError);

  useEffect(() => {
    const timeoutFn = setTimeout(function() {
      setCardAnimation("");
    }, 700);
    return () => {
      clearTimeout(timeoutFn);
    };
  }, []); // run once on start and return cleanup code

  const { response, loading, error, reFetch } = useAxios({
    url: `${process.env.REACT_APP_API_URL}/authenticate`,
    method: "POST",
    options: {
      httpsAgent: new https.Agent({
        rejectUnauthorized: false
      }),
      data: {
        username,
        password
      },
      headers: {
        "Content-Type": "application/json"
      }
    }
  });

  useEffect(() => {
    if (!loading && authenticate) {
      if (error) {
        alertError.current.showAlert({
          title: "Error occurred",
          contentText: error
        });
        setAuthenticate(false);
      } else if (response) {
        if (
          response.data.error &&
          response.data.error === "authentication_error"
        ) {
          localStorage.setItem("token", "");
          alertError.current.showAlert({
            title: "Authentication error",
            contentText: "Invalid username/password"
          });
        } else if (response.data.token) {
          localStorage.setItem("token", response.data.token);
          setRedirectToReferrer(true);
        }
        setAuthenticate(false);
      }
    }
  }, [response, loading, error]);

  const handleAuthenticate = () => {
    if (username === "" || password === "") {
      alertError.current.showAlert({
        title: "Validation error",
        contentText: "Username and password are required"
      });
    } else {
      reFetch();
      setAuthenticate(true);
    }
  };

  return redirectToReferrer ? (
    <Redirect push to="/" />
  ) : (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <form>
            <Card login className={classes[cardAnimation]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="rose"
              >
                <h4 className={classes.cardTitle}>Log in</h4>
              </CardHeader>
              <CardBody>
                <CustomInput
                  labelText="Username..."
                  id="username"
                  value={username}
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: event => setUsername(event.target.value),
                    endAdornment: (
                      <InputAdornment position="end">
                        <Face className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    )
                  }}
                />
                <CustomInput
                  labelText="Password"
                  id="password"
                  value={password}
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: event => setPassword(event.target.value),
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon className={classes.inputAdornmentIcon}>
                          lock_outline
                        </Icon>
                      </InputAdornment>
                    ),
                    type: "password",
                    autoComplete: "off"
                  }}
                />
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <Button
                  color="rose"
                  simple
                  size="lg"
                  block
                  onClick={handleAuthenticate}
                >
                  Submit
                </Button>
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
      <AlertError innerRef={alertError} />
    </div>
  );
};

export default LoginPage;
