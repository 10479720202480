import React, { useState, useEffect, useRef } from "react";
import useAxios from "@use-hooks/axios";
import https from "https";
import moment from "moment";

import { makeStyles } from "@material-ui/styles";

import { Paper, IconButton, InputBase, Box } from "@material-ui/core";

// custom components
import AlertError from "../../../components/Alert/AlertError";

// Icons
import SearchIcon from "@material-ui/icons/Search";
import Assignment from "@material-ui/icons/Assignment";

import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import Table from "../../../components/Table/Table.jsx";
import Card from "../../../components/Card/Card.jsx";
import CardHeader from "../../../components/Card/CardHeader.jsx";
import CardIcon from "../../../components/Card/CardIcon.jsx";
import CardBody from "../../../components/Card/CardBody.jsx";

import logo from "assets/img/logo-get-driven.png";

// Styles
import dashboardStyle from "../../../assets/jss/material-dashboard-pro-react/views/dashboardStyle";

const PLAN_ID = '929b7fdd-d638-454c-8d80-deb9812e0cf1'

const useStyles = makeStyles(() => ({
  ...dashboardStyle,
  container: {
    padding: 10,
    marginTop: 20,
    marginBottom: 20
  },
  searchContainer: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "100%",
    maxWidth: 400
  },
  input: {
    marginLeft: 8,
    flex: 1
  },
  iconButton: {
    padding: 10
  }
}));

const TakeMeHomePage = props => {
  const classes = useStyles();

  // const [idNumber, setIdNumber] = useState("8905166327181");
  const [searchValue, setSearchValue] = useState(
    process.env.NODE_ENV === "development" ? "6310150003082" : ""
  ); // 8106170146085 // 8804010298088 // 7501315155085
  const [member, setMember] = useState(undefined);
  const [trips, setTrips] = useState([]);
  const [search, setSearch] = useState(false);

  const alertError = useRef(AlertError);

  const token = localStorage.getItem("token");

  const { response, loading, error, reFetch } = useAxios({
    // url: `https://www.roadtrip.co.za/utils/getmember.php?value=${searchValue}`,
    // url: `${process.env.REACT_APP_API_URL}/members/search/${searchValue}/plan/929b7fdd-d638-454c-8d80-deb9812e0cf1`,
    url: `${process.env.REACT_APP_API_URL}/members/search/${searchValue}`,
    method: "GET",
    options: {
      httpsAgent: new https.Agent({
        rejectUnauthorized: false
      }),
      headers: {
        Authorization: `Bearer ${token}`
      }
    },
    trigger: search,
    forceDispatchEffect: () => !!search
  });

  const { response: vResponse } = useAxios({
    // url: `https://www.roadtrip.co.za/utils/getvehicles.php?value=${member &&
    //  member.id}`,
    url: `${process.env.REACT_APP_API_URL}/members/ids/${member &&
      member.id}/vehicles`,
    method: "GET",
    options: {
      httpsAgent: new https.Agent({
        rejectUnauthorized: false
      }),
      headers: {
        Authorization: `Bearer ${token}`
      }
    },
    trigger: member,
    forceDispatchEffect: () => !!member
  });

  const { response: tcResponse, loading: tcLoading, error: tcError } = useAxios(
    {
      // url: `${reportURL}/generate`,
      url: `${process.env.REACT_APP_API_URL}/members/ids/${member &&
        member.id}/trips`,
      method: "GET",
      options: {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      },
      trigger: member,
      forceDispatchEffect: () => !!member
    }
  );

  useEffect(() => {
    if (!loading) {
      setTrips([]);
      if (error) {
        setMember(undefined);
        setSearch(false);
        alertError.current.showAlert({
          title: error.toJSON() && error.toJSON().name,
          contentText: error.toJSON() && error.toJSON().message
        });
      } else if (response && search) {
        setSearch(false);
        const members = response.data.result.filter(
          row => row.plan_id === PLAN_ID && !Number(row.disabled)
        );
        if (!members.length) {
          setMember(undefined);
          alertError.current.showAlert({
            title: "Error occurred",
            contentText: "ID number not found"
          });
        } else if (members.length > 1) {
          setMember(undefined);
          alertError.current.showAlert({
            title: "Error occurred",
            contentText: "Multiple records found"
          });
        } else {
          setMember(members[0]);
        }
      }
    }
  }, [loading]);

  useEffect(() => {
    if (!tcLoading) {
      if (tcError) {
        setTrips([]);
        alertError.current.showAlert({
          title: tcError.toJSON() && tcError.toJSON().name,
          contentText: tcError.toJSON() && tcError.toJSON().message
        });
      } else if (!tcLoading && tcResponse) {
        setTrips(tcResponse.data.rows || []);
      }
    }
  }, [tcLoading]);

  const onKeyDown = event => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const handleSearch = () => {
    setSearchValue.length > 0 && setSearch(true);
    setTrips([]);
    setMember(undefined);
  };

  const remaining =
    tcLoading || (member && member.disabled === "1")
      ? 0
      : Math.max(
          0,
          4 -
            trips.filter(
              trip =>
                moment(trip.date, "DD/MM/YYYY").diff(
                  moment().startOf("month")
                ) > 0
            ).length
        );

  return (
    <React.Fragment>
      <GridContainer>
        <Card>
          <CardHeader icon>
            <CardIcon color="info" style={{ background: "white" }}>
              <img src={logo} style={{ maxWidth: 200 }} />
            </CardIcon>
          </CardHeader>
          <CardBody>
            <ul>
              <li>Search for client using ID number only</li>
              <li>
                Once you have located the client, book a trip for them against
                the Membership number that we have allocated to them
              </li>
              <li>
                EVEN IF THE CLIENT HAS 0 FREE TRIPS AVAILABLE TO THEM YOU CAN
                STILL ASSIST THEM!
              </li>
            </ul>
          </CardBody>
        </Card>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12}>
          <Paper className={classes.searchContainer} elevation={1}>
            <InputBase
              className={classes.input}
              placeholder="Enter ID Number..."
              value={searchValue}
              onChange={event => setSearchValue(event.target.value)}
              onKeyDown={onKeyDown}
            />
            <IconButton
              className={classes.iconButton}
              aria-label="Search"
              onClick={handleSearch}
            >
              <SearchIcon />
            </IconButton>
          </Paper>
        </GridItem>
        {member ? (
          <Card
            style={{
              backgroundColor: member.disabled === "1" ? "#ffcccb" : "#ffffff"
            }}
          >
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                {member.first_name} {member.surname}
              </h4>
            </CardHeader>
            <CardBody
              style={{
                backgroundColor: member.disabled === "1" ? "#ffcccb" : "#ffffff"
              }}
            >
              <GridContainer>
                <GridItem GridItem xs={5}>
                  <GridContainer>
                    <GridItem xs={6} style={{ marginBottom: 20 }}>
                      <Box fontWeight="fontWeightBold">Trips Remaining</Box>
                    </GridItem>
                    <GridItem xs={6}>
                      <Box
                        fontSize="32pt"
                        fontWeight={
                          remaining < 1 ? "fontWeightBold" : "fontWeightNormal"
                        }
                        color={remaining < 1 ? "error.main" : "text.primary"}
                      >
                        {remaining}
                      </Box>
                    </GridItem>
                    <GridItem xs={6}>
                      <Box fontWeight="fontWeightBold">ID Number</Box>
                    </GridItem>
                    <GridItem xs={6}>{member.id_number}</GridItem>
                    <GridItem xs={6}>
                      <Box fontWeight="fontWeightBold">Cell Number</Box>
                    </GridItem>
                    <GridItem xs={6}>{member.contact_cellular}</GridItem>
                    <GridItem xs={6}>
                      <Box fontWeight="fontWeightBold">Membership Number</Box>
                    </GridItem>
                    <GridItem xs={6}>{member.member_number}</GridItem>
                    <GridItem xs={6}>
                      <Box fontWeight="fontWeightBold">Policy Number</Box>
                    </GridItem>
                    <GridItem xs={6}>{member.policy_number}</GridItem>
                    <GridItem xs={6}>
                      <Box fontWeight="fontWeightBold">Account Disabled</Box>
                    </GridItem>
                    <GridItem xs={6}>
                      <Box
                        fontWeight={
                          member.disabled === "1"
                            ? "fontWeightBold"
                            : "fontWeightNormal"
                        }
                        color={
                          member.disabled === "1"
                            ? "error.main"
                            : "text.primary"
                        }
                      >
                        {member.disabled === "1" ? "YES" : "NO"}
                      </Box>
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem xs={12}>
                  <Table
                    tableHeaderColor="primary"
                    tableHead={[
                      "Account num",
                      "Trip date",
                      "Trip start",
                      "Job end",
                      "Passenger",
                      "Pick up",
                      "Drop off",
                      "Driver",
                      "Distance"
                    ]}
                    tableData={trips
                      .sort((firstEl, secondEl) =>
                        Number(firstEl.job_id) > Number(secondEl.job_id)
                          ? -1
                          : 1
                      )
                      .map(row => [
                        row.account_num,
                        row.date,
                        row.start,
                        row.end,
                        row.passenger,
                        row["pick-up"],
                        row.drop_off,
                        row.driver,
                        row.distance
                      ])}
                    coloredColls={[3]}
                    colorsColls={["primary"]}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        ) : null}
        <AlertError innerRef={alertError} />
      </GridContainer>
    </React.Fragment>
  );
};

export default TakeMeHomePage;
