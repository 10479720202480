/*!

=========================================================
* Material Dashboard PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import jwtDecode from "jwt-decode";
import PropTypes from "prop-types";

// Themes
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

import AuthLayout from "layouts/Auth.jsx";
import RtlLayout from "layouts/RTL.jsx";
import AdminLayout from "layouts/Admin.jsx";

import "assets/scss/material-dashboard-pro-react.scss?v=1.7.0";

const hist = createBrowserHistory();

const theme = createMuiTheme();

const isTokenExpired = token => {
  try {
    const decoded = jwtDecode(token);
    // peoch time to milliseconds
    if (decoded.exp && decoded.exp * 1000 < Date.now()) {
      // Checking if token is expired.
      return true;
    } else return false;
  } catch (err) {
    // console.log("expired check failed! Line 42: AuthService.js");
    return true;
  }
};

const PrivateRoute = ({ Component, location, ...rest }) => {
  const token = localStorage.getItem("token");
  return isTokenExpired(token) ? (
    <Redirect
      push
      to={{
        pathname: "/auth/login-page",
        state: { from: location }
      }}
    />
  ) : (
    <Route {...rest} render={props => <Component {...props} />} />
  );
};

PrivateRoute.propTypes = {
  Component: PropTypes.object,
  location: PropTypes.object
};

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Router history={hist}>
      <Switch>
        <Route path="/auth" component={AuthLayout} />
        <PrivateRoute path="/admin" component={AdminLayout} />
        <Redirect from="/" to="/admin/king-price-page" />
      </Switch>
    </Router>
  </ThemeProvider>,
  document.getElementById("root")
);
